import React from 'react';
import {
  countries as defaultCountryList,
  defaultCountry,
  defaultLanguage,
  languages as defaultLanguageList,
} from '../../config/i18n';

type Context = {
  languages: string[];
  language: string;
  defaultLanguage: string;
  countries: string[];
  country: string;
  defaultCountry: string;
};

type Props = {
  pageContext: {
    languages?: string[];
    language?: string;
    countries?: string[];
    country?: string;
  };
};

export const LocaleContext = React.createContext<Context>({
  languages: [],
  language: '',
  defaultLanguage,
  countries: [],
  country: '',
  defaultCountry,
});

const LocaleProvider: React.FC<Props> = ({
  children,
  pageContext: {
    languages = defaultLanguageList,
    language = defaultLanguage,
    countries = defaultCountryList,
    country = defaultCountry,
  },
}) => (
  <LocaleContext.Provider
    value={{
      languages,
      language,
      defaultLanguage,
      countries,
      country,
      defaultCountry,
    }}
  >
    {children}
  </LocaleContext.Provider>
);

export default LocaleProvider;
