import * as ReactDOM from 'react-dom';
const loadableReady = require('@loadable/component').loadableReady;
import CustomLayout from './wrapPageElement';
export const wrapPageElement = CustomLayout;
import './src/styles/global.css';

export const onRouteUpdate = ({ location: { search } }) => {
  const ref = new URLSearchParams(search).get('ref');
  if (ref) {
    document.querySelectorAll('a').forEach((item) => {
      if (item.href) {
        const url = new URL(item.href);
        if (
          url.hostname.match(/^(?=.*(ricohtours.com|ricohprojects.com)).*$/) ||
          url.hostname === 'localhost' ||
          !url.origin
        ) {
          const urlParamRef = url.searchParams.get('ref');
          if (!urlParamRef) {
            url.searchParams.set('ref', ref);
            item.href = url;
          }
        }
      }
    });
  }
  return false; // ref: https://github.com/gatsbyjs/gatsby/issues/3249#issuecomment-360868222
};

export const shouldUpdateScroll /* GatsbyBrowser['shouldUpdateScroll']  */ = ({
  prevRouterProps,
  routerProps,
}) =>
  prevRouterProps?.location.pathname.replace(/\/$/, '') !==
  routerProps.location.pathname.replace(/\/$/, '');

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
