import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import LocaleProvider from './src/providers/LocaleProvider';
import PageContextProvider from './src/providers/PageContextProvider';

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <PageContextProvider {...props}>
    <LocaleProvider {...props}>{element}</LocaleProvider>
  </PageContextProvider>
);

wrapPageElement.propTypes = LocaleProvider.propTypes;

export default wrapPageElement;
