export const defaultLanguage = 'en';
export const languages = ['en', 'es', 'fr', 'ja'];

export const defaultCountry = 'ww';
export const countries = ['ww', 'us', 'eu', 'jp'];

export const rewrites: { [key: string]: string } = {
  eu: 'ad,at,ax,be,bl,cy,de,ee,es,fi,fr,gf,gp,gr,ie,it,lu,lv,mc,mf,mq,mt,nl,pm,pt,re,si,sk,sm,tf,va,yt,gb',
  us: 'us,ca,mx',
  jp: 'jp',
};
