import React from 'react';

type Context = {
  title?: string;
  description?: string;
  canonical?: string;
  theme?: string;
};

type Props = {
  pageContext: Context;
};

export const PageContext = React.createContext<Context>({});

const PageContextProvider: React.FC<Props> = ({ children, pageContext }) => (
  <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
);

export default PageContextProvider;
